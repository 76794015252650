import React from 'react';
import { SwapList } from './SwapList';

export function StacksSwapsDashboard({ type }) {
  return (
    <>
      <SwapList />
    </>
  );
}
